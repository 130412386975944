<template>
  <div>
    <div class="white mb-60">
      <v-container>
        <v-row>
          <v-col cols="12" xl="2" lg="3" class="pt-0 d-none d-lg-block">
            <Navbar />
          </v-col>
          <v-col cols="12" xl="10" lg="9">
            <v-col cols="12" v-if="loading">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
            <!-- <h5> {{ SERVER+slides[0].imagens }}</h5> -->
            <v-carousel cycle hide-delimiter-background show-arrows-on-hover height="470">
              <v-carousel-item name="main-slide" v-for="(item, i) in slides" :key="i++" reverse-transition="fade-transition" transition="fade-transition">
                <img class="carousel-image" :src="SERVER + item.imagens" />
                <!-- @/assets/images/slides/Loja01.jpg -->
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- section-2  -->
    <!-- <div class="container mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/light.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Flash Deals</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
        </v-col>

        <v-col cols="12" class="px-0">
          <div class="">
            <VueSlickCarousel
              class="slick-secondary"
              :arrows="true"
              :dots="false"
              v-bind="slickSettings"
            >
              <v-col
                cols="12"
                v-for="(item, index) in getProducts.slice(16, 24).map((n) => n)"
                :key="index"
              >
                <CardCart
                  :content-img="item.img"
                  :content-text="item.title"
                  :counter="item.qty"
                  class="m-5"
                  @cartRemove="removeCart(item)"
                  @cartAdd="addCart(item)"
                />
              </v-col>

              <template #prevArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow prev slick-prev"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <template #nextArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow next slick-next"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
            </VueSlickCarousel>
          </div>
        </v-col>
      </v-row>
    </div> -->

    <!-- section-3  -->
    <!-- <div class="container mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2 primary--text"
                src="@/assets/images/icons/categories.svg"
                alt=""
              />
              <h2 class="font-weight-bold">Lojas de Top</h2>
            </div>
            <router-link
              to="/portal/pages/lojas"
              class="text-decoration-none blue-grey--text darken-1"
            >
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link>
          </div>
        </v-col>
      </v-row>
      <div>
        <VueSlickCarousel
          class="slick-secondary"
          :arrows="true"
          :dots="false"
          v-bind="slickSettingsTwo"
        >
          <div class="m-5" v-for="(n, index) in topCategories" :key="index">
            <v-col cols="12" class="">
              <router-link
                :to="{
                  name: 'LojasIndex',
                }"
              >
                <base-card class="">
                  <v-card-text
                    class="d-flex justify-content-center p-4 mx-auto"
                  >
                    <div class="card-overlay-item br-8 overflow-hidden">
                      <v-chip
                        class="mt-2 ma-2 p-absolute"
                        color="secondary"
                        small
                      >
                        Headphone
                      </v-chip>
                      <v-chip
                        class="mt-2 ma-2 p-absolute right-0"
                        color="grey lighten-3"
                        small
                      >
                        Headphone
                      </v-chip>
                      <a href="">
                        <img
                          class="top-categories-img br-8 mx-auto"
                          :src="n.img"
                          alt=""
                        />
                      </a>
                    </div>
                  </v-card-text>
                </base-card>
              </router-link>
            </v-col>
          </div>
          <template #prevArrow="arrowOption">
            <v-btn
              class="mx-2 slick-arrow prev slick-prev"
              fab
              dark
              small
              color="secondary"
            >
              <v-icon dark>
                {{ arrowOption }}
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <template #nextArrow="arrowOption">
            <v-btn
              class="mx-2 slick-arrow next slick-next"
              fab
              dark
              small
              color="secondary"
            >
              <v-icon dark>
                {{ arrowOption }}
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </VueSlickCarousel>
      </div>
    </div> -->

    <!-- section-4  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="6" xl="6">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/ranking-1.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Top Ratings</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
          <div>
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="6"
                    md="4"
                    lg="3"
                    xl="3"
                    v-for="(n, index) in topRatings"
                    :key="index"
                  >
                    <router-link to="/single-product">
                      <v-img
                        class="mb-4 card-overlay-item"
                        contain
                        :src="n.img"
                      ></v-img>
                    </router-link>
                    <div class="d-flex align-center justify-center mb-1">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="16"
                      ></v-rating>

                      <div class="grey--text text--darken-4 ml-1">(49)</div>
                    </div>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <h5 class="text-center mb-1 grey--text text--darken-3">
                        {{ n.title }}
                      </h5>
                      <h5 class="primary--text text-center">{{ n.price }}</h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
        <v-col cols="12" lg="6" xl="6">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/Group.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Featured Brands</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
          <div>
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(n, index) in featuredBrands"
                    :key="index"
                  >
                    <router-link to="/single-product">
                      <v-img
                        class="mb-4 card-overlay-item br-8"
                        cover
                        aspect-ratio="1.7"
                        :src="n.img"
                      ></v-img>
                    </router-link>
                    <router-link
                      to="/single-product"
                      class="text-decoration-none"
                    >
                      <h5 class="grey--text text--darken-4">{{ n.title }}</h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-5  -->
    <!-- <v-container class="mb-60" name="novos produtos">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/new-product-1.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Novos Produtos</h2>
            </div>

            <router-link
              to="/portal/page/novos/produtos"
              class="text-decoration-none blue-grey--text darken-1"
            >
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link>
          </div>
          <div name="novos-produtos">
            <base-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="6"
                    md="4"
                    lg="2"
                    xl="2"
                    v-for="(product, index) in produtos"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        name: 'ProdutoDetalhes',
                        params: { produto_id: product.id },
                      }"
                      class="text-decoration-none"
                    >
                      <v-img
                        class="mb-4 br-8 card-overlay-item"
                        contain
                        :src="product.imagem_produto"
                      ></v-img>

                      <h5 class="mb-1 grey--text text--darken-3 text-truncate">
                        {{ product.designacao }}
                      </h5>
                      <h5 class="primary--text">
                        {{ formatePrice(product.preco_venda) }} AKZ
                      </h5>
                    </router-link>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </div>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-6  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon mr-2"
                src="@/assets/images/icons/gift.svg"
                alt=""
              />

              <h2 class="font-weight-bold">Big Discounts</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
        </v-col>
        <v-col cols="12" class="px-0">
          <div>
            <VueSlickCarousel
              class="slick-secondary"
              :arrows="true"
              :dots="false"
              v-bind="slickSettingsThree"
            >
              <div class="m-5" v-for="(n, index) in bigDiscounts" :key="index">
                <v-col cols="12">
                  <base-card>
                    <v-card-text>
                      <router-link
                        to="/single-product"
                        class="text-decoration-none"
                      >
                        <v-img
                          class="card-overlay-item mb-2 br-8"
                          :src="n.img"
                        ></v-img>
                      </router-link>
                      <router-link
                        to="/single-product"
                        class="text-decoration-none"
                      >
                        <h5 class="grey--text text--darken-4">{{ n.title }}</h5>
                      </router-link>
                      <div class="d-flex">
                        <h5 class="primary--text me-2">$250</h5>
                        <h5
                          class="grey--text text--darken-1 text-decoration-line-through"
                        >
                          $250
                        </h5>
                      </div>
                    </v-card-text>
                  </base-card>
                </v-col>
              </div>
              <template #prevArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow prev slick-prev"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <template #nextArrow="arrowOption">
                <v-btn
                  class="mx-2 slick-arrow next slick-next"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
            </VueSlickCarousel>
          </div>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-7  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <div class="" v-for="(brand, index) in brandsIcon" :key="index">
                <v-btn
                  block
                  class="mb-3 text-capitalize py-5"
                  elevation="0"
                  color="grey lighten-2 "
                >
                  <v-avatar size="16" tile class="me-4">
                    <img :src="brand.image" alt="" />
                  </v-avatar>
                  <span class="font-600">{{ brand.name }}</span>
                </v-btn>
              </div>
              <div class="mb-60"></div>
              <v-btn
                class="text-capitalize py-5"
                block
                elevation="0"
                color="grey lighten-2 "
              >
                <span class="font-600">View All Brands</span>
              </v-btn>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" lg="9" xl="10">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">Cars</h2>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
              v-for="(item, index) in getProducts.slice(25, 32).map((n) => n)"
              :key="index"
            >
              <CardCart
                cardHoverShadow
                :content-img="item.img"
                :content-text="item.title"
                :counter="item.qty"
                class="m-5"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-8  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="black"
                class="brand-tabs mb-3"
              >
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Brands</h3>
                </v-tab>
                <div class="d-flex align-center grey--text text--lighten-1">
                  |
                </div>
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Shops</h3>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in 2" :key="item">
                  <div
                    class=""
                    v-for="(brand, index) in brandsIcon"
                    :key="index"
                  >
                    <v-btn
                      block
                      class="mb-3 text-capitalize py-5"
                      elevation="0"
                      color="grey lighten-2 "
                    >
                      <v-avatar size="16" tile class="me-4">
                        <img :src="brand.image" alt="" />
                      </v-avatar>
                      <span class="font-600">{{ brand.name }}</span>
                    </v-btn>
                  </div>
                  <div class="mb-60"></div>
                  <v-btn
                    class="text-capitalize py-5"
                    block
                    elevation="0"
                    color="grey lighten-2 "
                  >
                    <span class="font-600">View All Brands</span>
                  </v-btn>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col cols="12" lg="9" xl="10">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">Mobile Phones</h2>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
              v-for="(item, index) in getProducts.slice(32, 40).map((n) => n)"
              :key="index"
            >
              <CardCart
                cardHoverShadow
                :content-img="item.img"
                :content-text="item.title"
                :counter="item.qty"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
                :amount="item.amount"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-9  -->
    <!-- <v-container class="mb-60">
      <div class="row">
        <v-col cols="12" sm="6" md="4" lg="4">
          <a href="#">
            <v-img contain src="@/assets/images/banners/banner-1.png"></v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="8">
          <a href="#">
            <v-img contain src="@/assets/images/banners/banner-2.png"></v-img>
          </a>
        </v-col>
      </div>
    </v-container> -->

    <!-- section-10  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12" lg="3" xl="2">
          <base-card>
            <v-card-text>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="black"
                class="brand-tabs mb-3"
              >
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Brands</h3>
                </v-tab>
                <div class="d-flex align-center grey--text text--lighten-1">
                  |
                </div>
                <v-tab class="text-capitalize">
                  <h3 class="font-weight-bold">Shops</h3>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in 2" :key="item">
                  <div
                    class=""
                    v-for="(brand, index) in brandsIcon"
                    :key="index"
                  >
                    <v-btn
                      block
                      class="mb-3 text-capitalize py-5"
                      elevation="0"
                      color="grey lighten-2 "
                    >
                      <v-avatar size="16" tile class="me-4">
                        <img :src="brand.image" alt="" />
                      </v-avatar>
                      <span class="font-600">{{ brand.name }}</span>
                    </v-btn>
                  </div>
                  <div class="mb-60"></div>
                  <v-btn
                    class="text-capitalize"
                    block
                    elevation="0"
                    color="grey lighten-2 "
                  >
                    <span class="font-600">View All Brands</span>
                  </v-btn>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </base-card>
        </v-col>

          <v-col cols="12" lg="9" xl="10">
            <div class="d-flex justify-space-between mb-5">
              <h2 class="font-weight-bold">Optics / Watch</h2>
              <a href="#" class="text-decoration-none blue-grey--text darken-1">
                View all
                <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
              </a>
            </div>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="4"
                xl="3"
                v-for="(item, index) in getProducts.slice(41, 48).map((n) => n)"
                :key="index"
              >
                <CardCart
                  cardHoverShadow
                  :content-img="item.img"
                  :content-text="item.title"
                  :counter="item.qty"
                  @cartRemove="removeCart(item)"
                  @cartAdd="addCart(item)"
                  :amount="item.amount"
                />
              </v-col>
            </v-row>
          </v-col>
      </v-row>
    </v-container> -->

    <!-- section-11  -->
    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon primary--text mr-2"
                src="@/assets/images/icons/categories.svg"
                alt=""
              />

              <h2 class="font-weight-bold" name="all-cateogries">
                Todas categorias
              </h2>
            </div>

            <router-link
              to="/portal/pages/categorias"
              class="text-decoration-none blue-grey--text darken-1"
            >
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link>
          </div>
        </v-col>
        <v-col
          v-for="(n, index) in categories"
          :key="index"
          cols="6"
          sm="4"
          md="3"
          lg="2"
        >
          <router-link
            :to="{
              name: 'portal-todas-as-categorias',
            }"
            class="text-decoration-none"
          >
            <base-card class="card-hover-shadow">
              <v-card-text>
                <div class="d-flex align-center">
                  <v-avatar tile>
                    <img class="mr-3" :src="n.imagem" alt="" />
                  </v-avatar>
                  <p class="font-weight-bold mb-0 text-truncate">
                    {{ n.designacao }}
                  </p>
                </div>
              </v-card-text>
            </base-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- section-12  -->
    <v-container class="mb-60" name="produtos-em-destaque">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold" name="section-more-for-you">
              Em destaque
              <!-- {{ slides[0]}} -->
            </h2>

            <router-link to="/portal/page/produtos/em-destaque" class="text-decoration-none blue-grey--text darken-1">
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link>
          </div>

          <v-row>
            <v-divider></v-divider>
            <v-col cols="12" v-if="loading">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in produtos" :key="index">
              <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :amount="item.pvp" :produto="item" />
              <!-- :classificacao="item.classificacao" -->
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col v-if="loading">
          <div class="text-center mt-12">
            <v-progress-circular size="60" indeterminate color="primary" />
          </div>
        </v-col> -->
      </v-row>
    </v-container>

    <!-- <v-container class="mb-60" name="produtos-mais-vendidos">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold" name="section-more-for-you">
              Mais Vendidos
            </h2>

            <router-link :to="{ name: 'ProdutosMaisVendidos' }" class="text-decoration-none blue-grey--text darken-1">
              Ver tudo <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </router-link>
          </div>

          <v-row>
            <v-divider></v-divider>
            <v-col cols="12" v-if="loading">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in produtos_mais_vendidos" :key="index">
              <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :counter="item.qty" @cartRemove="removeCart(item)" @cartAdd="addCart(item)" :amount="item.preco_venda" :produto="item" />
    </v-col>
    </v-row>
    </v-col>
    </v-row>
    </v-container> -->

    <!-- section-13  -->

    <!-- <v-container class="mb-60">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="0" cardHoverShadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5">
                <img v-svg-inline class="icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/truck.svg" alt="" />

              </div>
              <h4 class="font-weight-medium text-uppercase grey--text text--darken-4 mb-3">
                Entrega Nacional
              </h4>
              <v-card-subtitle class="text-center grey--text text--darken-2 pt-0">Entregamos em todas as provincias do Pais</v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="0" cardHoverShadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5">
                <img v-svg-inline class="icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/credit.svg" alt="" />
              </div>
              <h4 class="font-weight-medium text-uppercase grey--text text--darken-4 mb-3">
                Pagamento Seguro
              </h4>
              <v-card-subtitle class="text-center grey--text text--darken-2 pt-0">Nosso sistema de Pagamento é Encriptado com SSL
              </v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="0" cardHoverShadow class="py-48">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5">
                <img v-svg-inline size="150" class="icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/shield.svg" alt="" />
              </div>
              <h4 class="font-weight-medium text-uppercase grey--text text--darken-4 mb-3">
                Compre com Confiança
              </h4>
              <v-card-subtitle class="text-center grey--text text--darken-2 pt-0">
                Nosso sistema de Pagamento é Encriptado com SSL
              </v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="0" cardHoverShadow class="py-48" text>
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5">
                <img v-svg-inline size="150" class="icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/headphone.svg" alt="" />
              </div>
              <h4 class="font-weight-medium text-uppercase grey--text text--darken-4 mb-3">
                Atendimento 24/7
              </h4>
              <v-card-subtitle class="text-center grey--text text--darken-2 pt-0">Callcenter dedicado 24 horas para os nossos
                clientes.</v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

    </v-container> -->

    <v-container>
      <v-col cols="12" class="mb-8">
        <div class="border-grey br-8">
          <div class="d-flex justify-space-between flex-wrap px-10 py-7">
            <div class="d-flex align-center">
              <v-avatar size="50" tile class="mr-3">
                <img v-svg-inline class="icon mr-2 primary--text" src="@/assets/images/icons/delivery-truck.svg" alt="" />
              </v-avatar>
              <div>
                <h3>Entrega Rápida</h3>
                <span class="text-14 grey--text text--darken-2">Entrega apenas para Luanda</span>
              </div>
            </div>

            <!-- <div class="d-flex align-center">
              <v-avatar size="50" tile class="mr-3">
                <img
                  v-svg-inline
                  class="icon mr-2 primary--text"
                  src="@/assets/images/icons/piggy-bank.svg"
                  alt=""
                />
              </v-avatar>
              <div>
                <h3>Reembolso Rápido</h3>
                <span class="text-14 grey--text text--darken-2"
                  >Reembolso em 1 semana</span
                >
              </div>
            </div> -->
            <!-- <div class="d-flex align-center">
              <v-avatar size="50" tile class="mr-3">
                <img v-svg-inline class="icon mr-2 primary--text" src="@/assets/images/icons/alarm-clock.svg" alt="" />
              </v-avatar>
              <div>
                <h3>365 Days</h3>
                <span class="text-14 grey--text text--darken-2">For Free Return</span>
              </div>
            </div> -->
            <div class="d-flex align-center">
              <v-avatar size="50" tile class="mr-3">
                <img v-svg-inline class="icon mr-2 primary--text" src="@/assets/images/icons/credit-card-2.svg" alt="" />
              </v-avatar>
              <div>
                <h3>Pagamento Seguro</h3>
                <span class="text-14 grey--text text--darken-2">
                  Transferência bancária ou TPA</span>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-avatar size="50" tile class="mr-3">
                <img v-svg-inline class="icon mr-2 primary--text" src="@/assets/images/icons/smartphone.svg" alt="" />
              </v-avatar>
              <div>
                <h3>Atendimento 24/7</h3>
                <span class="text-14 grey--text text--darken-2">Callcenter 24/7.</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-container>
    <v-col cols="12" class="text-center">
      <v-avatar size="50" class="mb-3">
        <img v-svg-inline class="icon mr-2 primary--text" src="@/assets/images/icons/telegram.svg" alt="" />
      </v-avatar>
      <h2 class="mb-4">Receber Notificações</h2>
      <p class="grey--text text--darken-2 mb-6">
        Receba no teu email os produtos mais baixos!
      </p>
      <div class="mb-8">
        <v-row>
          <v-col cols="12" md="6" lg="4" class="mx-auto">
            <div class="input-group-btn">
              <v-text-field solo placeholder="Digite aqui o Email" v-model="email" flat dense outlined hide-details=""></v-text-field>
              <v-btn elevation="0" color="secondary" class="font-600" @click="sendNewsLetter()">
                Subscrever agora
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-dialog name="dialog-logout-sucesso" v-model="dialogSucessoNewsletter" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2 text-uppercase">NOTIFICAÇÕES ATIVADAS POR EMAIL</h6>

          <v-col cols="12">
            <span>Obrigado por se inscrever para receber as notificações por email.
              Vamos te informar sobre os últimos produtos a serem
              publicados.</span>
          </v-col>
          <v-btn color="success" @click="dialogSucessoNewsletter = false">
            SAIR</v-btn>
          <!-- <v-progress-linear indeterminate color="secondary"></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog name="dialog-no-internet-acess" v-model="dialogNoInternetAcess" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="error" class="mt-2" size="50">mdi-cloud-off-outline</v-icon>
          <h6 class="mt-2">
            {{ alert.text }}
          </h6>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" dense outlined @click="getAllProdutos()">
            <v-icon>mdi-sync</v-icon> Tentar de Novo!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <dialog-no-internet-acess
      :dialog-visible="dialogNoInternetAcess"
      :alert-text="alert.text"
      :retry-request="getAllProdutos"
    ></dialog-no-internet-acess> -->
  </div>
</template>

<script>
import axios from "axios";

import Navbar from "@/views/home/navbar";
import CardCart from "@/components/cartCard/CardCart";
import { mapGetters, mapActions } from "vuex";
import { LOGIN_URL, SERVIDOR, BASE_URL } from "@/configs/api"; // import axios from "axios";

// import DiallogNoInternetAcess from "@/components/Dialogs/DialogNoInternetAcess.vue";

export default {
  components: {
    Navbar,
    CardCart,
  },
  // metaInfo() {
  //   return {
  //     title: 'Início - Mutue Loja Online',
  //     meta: [
  //       {
  //         name: 'description',
  //         content: 'A Mutue Loja Online está à sua disposição, pronta para transformar a maneira como você faz compras online. Imagine um mundo onde a busca pelo produto perfeito, a facilidade de compra e a satisfação do cliente se unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à Mutue!',
  //       },
  //    ],
  //   };
  // },
  computed: {
    ...mapGetters(["getProducts"]),
  },
  data() {
    return {
      email: "",
      slickSettingsSingleItem: {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
      dialogSucessoNewsletter: false,
      produtos_mais_vendidos: [],
      SERVER: SERVIDOR,
      dialogNoInternetAcess: false,
      alert: {
        type: "",
      },
      loading: false,
      slides: [
      ],
      user: {
        // email: "cliente@gmail.com",
        // password: "mutue123",
      },
      token: {},
      // csrfToken: {},
      img: require("@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png"),
      produtos: [],
      slickSettings: {
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,

        responsive: [
          {
            breakpoint: 2100,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              infinite: true,
            },
          },
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
            },
          },
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
            },
          },
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slickSettingsTwo: {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,

        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
      slickSettingsThree: {
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,

        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              infinite: true,
            },
          },
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
      brandsIcon: [
        {
          name: "TMW",
          image: require("@/assets/images/brands/v.png"),
        },
        {
          name: "Zerrari",
          image: require("@/assets/images/brands/u.png"),
        },
        {
          name: "Zord",
          image: require("@/assets/images/brands/v.png"),
        },
        {
          name: "Gini",
          image: require("@/assets/images/brands/u.png"),
        },
        {
          name: "Nesla",
          image: require("@/assets/images/brands/v.png"),
        },
      ],

      topRatings: [
        {
          img: require("@/assets/images/products/camera-1.png"),
          title: "Camera",
          price: "$3,300",
        },
        {
          img: require("@/assets/images/products/shoes-2.png"),
          title: "Shoe",
          price: "$400",
        },
        {
          img: require("@/assets/images/products/mobile-1.png"),
          title: "Mobile",
          price: "$999",
        },
        {
          img: require("@/assets/images/products/watch-1.png"),
          title: "Watch",
          price: "$600",
        },
      ],
      newArrivals: [
        {
          img: require("@/assets/images/products/imagegoggles.png"),
          title: "Sunglass",
          price: "$150",
        },
        {
          img: require("@/assets/images/products/lipstick(2).png"),
          title: "Lipstick",
          price: "$250",
        },
        {
          img: require("@/assets/images/products/lipstick(2).png"),
          title: "Lipstick",
          price: "$250",
        },
        {
          img: require("@/assets/images/products/bgwatch.png"),
          title: "Lipstick",
          price: "$250",
        },
        {
          img: require("@/assets/images/products/lipstick(4).png"),
          title: "Green Plant",
          price: "$250",
        },
        {
          img: require("@/assets/images/products/lipstick(3).png"),
          title: "Bonsai Tree",
          price: "$450",
        },
      ],
      bigDiscounts: [
        {
          img: require("@/assets/images/products/Electronics/7.beatsw3.png"),
          title: "BenX 2020",
        },
        {
          img: require("@/assets/images/products/Electronics/9.SonyTV1080p.png"),
          title: "Tony TV 1080p",
        },
        {
          img: require("@/assets/images/products/Electronics/10.SonyPS4.png"),
          title: "Tony PS4",
        },
        {
          img: require("@/assets/images/products/Electronics/11.Netgear2020.png"),
          title: "Setgearr 2020",
        },
        {
          img: require("@/assets/images/products/Electronics/12.SonyBGB.png"),
          title: "Tony BGB",
        },
        {
          img: require("@/assets/images/products/Electronics/13.LGProducts.png"),
          title: "RG Products",
        },
        {
          img: require("@/assets/images/products/Electronics/14.Panasonic2019.png"),
          title: "Sanasonic 2019",
        },
        {
          img: require("@/assets/images/products/Electronics/15.DuneHD.png"),
          title: "Zune HD",
        },
        {
          img: require("@/assets/images/products/Electronics/19.LGWashingMachine.png"),
          title: "JK Washing Machine",
        },
      ],
      topCategories: [
        {
          img: require("@/assets/images/banners/category-1.png"),
        },
        {
          img: require("@/assets/images/banners/category-2.png"),
        },
        {
          img: require("@/assets/images/banners/category-3.png"),
        },
        {
          img: require("@/assets/images/banners/category-1.png"),
        },
        {
          img: require("@/assets/images/banners/category-1.png"),
        },
        {
          img: require("@/assets/images/banners/category-2.png"),
        },
        {
          img: require("@/assets/images/banners/category-3.png"),
        },
        {
          img: require("@/assets/images/banners/category-1.png"),
        },
      ],
      featuredBrands: [
        {
          img: require("@/assets/images/products/london-britches.png"),
          title: "London Britches",
        },
        {
          img: require("@/assets/images/products/jimandjiko.png"),
          title: "Jim & Jago",
        },
      ],
      categories: [
        // {
        //   img: require("@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png"),
        //   title: "Tops",
        // },
        // {s
        //   img: require("@/assets/images/products/Fashion/Clothes/10.LandsJacket.png"),
        //   title: "Jacket",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png"),
        //   title: "Smart Watch",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png"),
        //   title: "Sunglasses",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Accessories/3.BrownCap.png"),
        //   title: "Cap",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Shoes/1.NikeRed.png"),
        //   title: "Nike Red",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Shoes/5.NikeBlue.png"),
        //   title: "Nike Silver",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png"),
        //   title: "Gold Necklace",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png"),
        //   title: "NBL Hoodie",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Clothes/10.LandsJacket.png"),
        //   title: "Jacket",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png"),
        //   title: "Tops",
        // },
        // {
        //   img: require("@/assets/images/products/Fashion/Clothes/10.LandsJacket.png"),
        //   title: "Jacket",
        // },
      ],
      heroSlider: [
        {
          img: require("@/assets/images/products/apple-watch-0.png"),
          name: "Desconto de 50% na tua primeira compra",
        },
        {
          img: require("@/assets/images/products/apple-watch-0.png"),
          name: "Desconto de 20% em produtos eletrónicos",
        },
        {
          img: require("@/assets/images/products/apple-watch-0.png"),
          name: "70% de desconto para produtos em promoção",
        },
      ],
      tab: null,
      items: ["Brands", "Shops"],
    };
  },
  mounted() {
    this.getAllInfoSlide();
    this.getAllCategories();
  },
  created() {
    this.getAllProdutos();
    this.getAllProdutosVendidos();
  },
  methods: {
    ...mapActions(["addCart", "removeCart"]),

    sendNewsLetter() {
      this.dialogSucessoNewsletter = true;
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    // portal/produtos/destaques
    getAllInfoSlide() {
      this.loading = true;
      this.dialogNoInternetAcess = false;
      this.$axios
        .get(LOGIN_URL + "/anuncios/banner")
        // http://192.168.30.24:82/api/
        .then((response) => {
          this.slides = response.data;
          // alert(JSON.stringify(response.data));
          setTimeout(() => {
            this.loading = false;
          }, 50);
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
            // this.alert.type = "error";
            // this.alert.text = "Não foi possivel conectar com o servidor!";
            // this.dialogNoInternetAcess = true;
          }, 50);
        });
    },
    getAllCategories() {
      this.loading = true;
      this.$axios
        .get(BASE_URL + "/listarCategorias")
        .then((response) => {
          this.categories = response.data;
          setTimeout(() => {
            this.loading = false;
          }, 50);
        })
        .catch((error) => {});
    },

    getAllProdutosVendidos() {
      this.loading = true;
      this.alert.error = null;
      this.dialogNoInternetAcess = false;
      axios
        .get(`${LOGIN_URL}/listarProdutosMaisVendidos`)
        .then((response) => {
          this.produtos_mais_vendidos = response.data.data;
          //  console.log(this.produtos_mais_vendidos)
          // alert(JSON.stringify(this.produtos_mais_vendidos))
          setTimeout(() => {
            this.loading = false;
          }, 50);
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
            // this.alert.type = "error";
            this.alert.text = "Não foi possivel conectar com o servidor!";
            this.dialogNoInternetAcess = true;
          }, 1000);
        });
    },

    getAllProdutos() {
      this.loading = true;
      this.alert.error = null;
      this.dialogNoInternetAcess = false;
      axios
        .get(`${BASE_URL}/listarProdutos`)
        .then((response) => {
          this.produtos = response.data.data;
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        })
        .catch((error) => {
          setTimeout(() => {
            this.loading = false;
            // this.alert.type = "error";
            this.alert.text = "Não foi possivel conectar com o servidor!";
            this.dialogNoInternetAcess = true;
          }, 50);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}
</style>
<style>
#format-card-curve {
  border-radius: 25px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-fit: cover; */
  background-size: cover;
  background-position: center;
}
</style>
